<template>
  <div v-drag>
    <a-modal title="导出"
             v-model:visible="dialogVisible"
             @ok="search"
             width="520rem">
      <a-row class="input_Warp">

        <a-radio-group v-model:value="radioValue">
          <a-radio :value="1">导出错题、答案和解析</a-radio>
          <a-radio :value="0">仅导出错题</a-radio>
        </a-radio-group>
      </a-row>
      <!-- <a-row class="input_Warp">
        <div style="width:80rem;font-size:18rem">题型</div>
        <a-select style="flex:1"
                  v-model:value="question_type_id"
                  placeholder="请选择"
                  allowClear
                  :options='typeList.map(item=>({label:item.question_type_name,value:item.question_type_id}))'>
        </a-select>
      </a-row> -->

      <template #footer>
        <div class="btn_warp">
          <div @click="handleClose">取消</div>
          <div @click="search">确定</div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script setup>

import { ref, toRefs, defineExpose } from "vue"
import { collectTagList } from "@/api/collect.js"
import { errorTopicType } from '@/api/errorBook.js'

let dialogVisible = ref(false)
let keywords = ref('')
let radioValue = ref(1)
let question_type_id = ref(undefined)
let typeList = ref([])
let subject_id = ref('')

const emit = defineEmits(['exportData'])
const handleClose = () => {
  dialogVisible.value = false
  question_type_id.value = undefined
  keywords.value = ''
}
const search = () => {

  emit('exportData', radioValue.value)
  handleClose()
}

const getTypeList = async () => {
  const { data } = await errorTopicType({
    subject_id: subject_id.value
  })
  typeList.value = data.list
}


defineExpose({
  getTypeList,
  subject_id,
  dialogVisible,
  keywords,
})

</script>

<style lang="scss" scoped>
.text_warp {
  word-break: break-all;
  font-weight: 500;
  color: #666666 !important;
  line-height: 23px;
  font-size: 16px;
  padding: 27px;
  padding-top: 0;
}

:deep .ant-input-affix-wrapper > input.ant-input {
  font-size: 18rem;
}

.btn_warp {
  // border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // margin-bottom: -30rem;
  div {
    flex: 1;
    font-weight: bold;
    font-size: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:nth-child(2) {
      color: #2196f3;
      border-left: 1px solid #e5e5e5;
    }
  }
}

.input_Warp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  span {
    flex: 1;
  }
}
</style>